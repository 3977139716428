import React, { useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import title2 from './../resources/img/institucional/title2.png'
import { Helmet } from "react-helmet";

const Procesos = (props) => {
    let { main } = props
    const width = '100%';

    const videocontainer = useRef();

    const video = 'https://www.youtube.com/embed/wmuGsPtbFMU?si=nOTFfiImGKBT7_KC'

    return <>
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{'Procesos - '+ main.get?.name}</title>
                <meta name="description" content={'Procesos'} />
                <meta name="keywords" content={'campaa procesos vimenca'} />
            </Helmet>
            <div className={'container p-5'}>
                {video && 
                    <Row>
                        <Col>
                        <div>
                            <div ref={videocontainer} className={'bgblack p-3 my-3'} dangerouslySetInnerHTML={{
                                __html:`<iframe width="${width}" height="500"  src="${video}" title="Procesos" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                            }}>
                            </div>
                        </div>
                        </Col>
                    </Row>
                }
            </div>    
        </div>
        </>
}

export default Procesos;
