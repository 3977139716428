import React, {useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import Moment from "moment";
import * as Icon from "react-feather";
import Uaf from "./resources/img/IMG_6828.png";
import Sello from "./resources/img/sello.png";
import axios from "axios";

export function Subtitle(props) {
    return <span className={props.bg+' p-1 font-weight-bold subtitlespan mb-3 text-uppercase '+props.fontcolor}>{props.value}</span>
}

export const fetchData = async (url, setContent=null) => {
    try {
        const response = await axios.get(url);
        if(response.status === 200){
            setContent && setContent(response.data)
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export const postData = async (url, body, headers) => {
    console.log(url, body, headers)
    try {
        const response = await axios.post(url, body, {
            headers
        });
        if(response.status === 200){
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export function ServiciosTransferencia(props){
    let { data } = props;
    return <div {...props}>
            <div className={'container py-5'}>
                <Row className={'p-5'}>
                    <Col className={'mb-5'} sm={12} md={12}>
                        <Subtitle class={'mt-0 mb-4 text-center'} bg={'bg-black'} fontcolor={'text-white'} value={'AHORRA TIEMPO Y EVITA DESPLAZAMIENTOS CON NUESTRO SERVICIO DE PAGO DE REMESA MEDIANTE TRANSFERENCIA BANCARIA'} />
                    </Col>
                    {data.map(item=>{
                        return(
                            <Col key={'serviciostrams'+item.id} sm={12} md={6}>
                                <div className={'bgyellow text-center card card-body'}>
                                    <img alt={item.titulo} className={'iconhomebanner'} src={props.domain+item.img}/>
                                    <h5 className={'font-weight-bold'}>{item.titulo}</h5>
                                    <p className={'mt-2'}>{item.descripcion}</p>
                                    <Link class={'mt-3'} link={item.link} variant={'secondary'} value={item.linktext}/>
                                </div>
                            </Col>
                        )
                    })}

                </Row>
            </div>
        </div>
}

export function Itemnew(props) {
    let {item,domain,md}=props
    let date=item.date
    return <Col key={'news'+item.id} className={props.class+' text-left'} xs={12} md={md}>
            <a href={'/'+item.alias+'/'+item.id}><img alt={item.item} src={domain+'/'+item.img}/></a>
            <span className={'font-weight-bold small d-inline-block mb-2'}>{Moment(date).format('LL')}</span>
            <a className={'text-secondary'} href={'/'+item.alias+'/'+item.id}>
                <Titlefourth value={item.title}/>
            </a>
        </Col>
}

export const Layout = props => {
    let {headerRef, main, global} = props
    main = main.get
    const [showmenu,setShowmenu] = useState(false)
    return <div className="mainLayout">
        <div>
            <Header headerRef={headerRef} domain={global.domain} data={main} stlylename={''} switch={()=>setShowmenu(!showmenu)}/>
            {props.children}
            <Contacto data={main}/>
            <Footer domain={global.domain} data={main}/>
        </div>
        <div className={showmenu ? 'sidemenu show': 'sidemenu hide'}>
            <div className={'bgyellow shadow-left h-100 '}>
                <Menu data={main.menuitems} switch={()=>setShowmenu(!showmenu)} />
            </div>
        </div>
    </div>
}

function Header(props){
    let logovi = props.data.main.logo
    let logo = props.data.main.logo_negative
    let {domain,headerRef} = props
    return <header ref={headerRef} className={'bg-white text-center position-relative '+props.stlylename}>
                <span className={'yellow px-2 togglemenu position-absolute'}>
                      <button onClick={props.switch}>
                          <Icon.Menu/>
                      </button>
                </span>
        <a href={'/'}>
            <img alt={'Vimenca'} className={'logovimenca ml-0 mt-2 mt-sm-0 mr-sm-4'}  src={domain+logovi}/>
            <img alt={'WesternUnion'} className={'logowestern mr-0 mr-sm-4'} src={domain+logo}/>
        </a>
    </header>
}

function Contacto(props) {
    let data=props.data.main
    return <div id={'contacto'} className={'py-5 bg-white'}>
        <div className={'text-center mt-5'}>
            <Subtitle bg={'bgyellow'} fontcolor={'text-black'} value={'Contacto'}/>
        </div>
        <div className={'container mb-5'}>
            <div className={'row mt-3'}>
                <div className={'col-12 mb-4 mt-3 text-center'}>
                    <Title color={'text-black'} value={'¿Necesitas asistencia?'}/>
                </div>
                <div className={'col-12 col-md'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-1 col-sm-2 col-lg-1'}>
                            <Icon.MapPin color={'#fddb00'}/>
                        </div>
                        <div className={'col-9 col-sm-10 col-lg-11'}>
                            <p className={'text-uppercase mb-0'}>OFICINA PRINCIPAL</p>
                            <p className={'mb-0'}>{data.address}</p>
                        </div>
                    </div>
                </div>
                <div className={'col-12 col-md mt-3 mt-md-0'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-1 col-sm-2 col-lg-1'}>
                            <Icon.Phone color={'#fddb00'}/>
                        </div>
                        <div className={'col-9 col-sm-10 col-lg-11'}>
                            <p className={'mb-0'}><a className={'text-black'} href={'tel:+1(809)532-7382'}>(809) 532-7382</a></p>
                        </div>
                    </div>

                </div>
                <div className={'col-12 col-md-3 mt-4 mt-md-0 text-center text-sm-left'}>
                    <p className={'mb-0'}>Síguenos en las redes</p>
                    <p>
                        {data.facebook ? <a rel={'noopener noreferrer'} href={data.facebook} target={'_blank'} className={'p-1'}><Icon.Facebook color={'#fddb00'}/></a> : null}
                        {data.youtube ? <a rel={'noopener noreferrer'} href={data.youtube} target={'_blank'} className={'p-1'}><Icon.Youtube color={'#fddb00'}/></a> : null}
                        {data.instagram ? <a rel={'noopener noreferrer'} href={data.instagram} target={'_blank'} className={'p-1'}><Icon.Instagram color={'#fddb00'}/></a> : null}
                        {data.twitter ? <a rel={'noopener noreferrer'} href={data.twitter} target={'_blank'} className={'p-1'}><Icon.Twitter color={'#fddb00'}/></a> : null}
                    </p>
                </div>

            </div>
        </div>
    </div>
}

function Footer(props){
    let logovi = props.data.main.logo
    let logo = props.data.main.logo_negative
    let domain = props.domain
    return <footer className={'bg-grey py-4 text-center'}>
        <div style={{margin:'25px 0'}}>
            <a href={'/'}>
                <img alt={'Vimenca'} className={'logovimenca ml-0 mt-3 mt-sm-0 mr-sm-4'}  src={domain+logovi}/>
                <img alt={'WesternUnion'} className={'logowestern mr-0 mr-sm-4'} src={domain+logo}/>
            </a>
        </div>
        <div className="w-100">
            <a href={'/terminos-de-uso/12'} className={'d-inline-block mb-3'}>Términos y condiciones de uso</a> <span>|</span> <a href={'/prevencion-de-fraudes/17'} className={'d-inline-block mb-3'}>Prevención de fraudes</a>
            <br/>
            <div className='sellos'>
                <a href={'https://certificaciones.uaf.gob.do/certificaciones_so_view.php?editid1=47'} rel="noopener noreferrer" target={'_blank'}>
                    <img alt={'Certificacion UAF'} src={Uaf}/>
                </a>
                <a className={'d-inline-block'} rel="noopener noreferrer" target={'_blank'} href={'https://sb.gob.do/entidades-autorizadas-sb/entidades/REMVIMENCA'}>
                    <img src={Sello} alt={'Sello entidad autorizada SB'}/>
                </a>
            </div>
        </div>
        <div className={'px-3'}>
            <p>{props.data.main.footertext}</p>
            <p style={{opacity:.5}}><small>Desarrollado por <a href={'https://www.host.do/'} title={'hosting'} rel="noopener noreferrer" target={'_blank'}>grupohost</a></small></p>
        </div>
    </footer>
}

function Menu(props) {
    let menuitems=props.data
    return  <nav className={'position-fixed'}>
        <ul className="nav flex-column">
            <li className="nav-item"><button className={'nav-link'} onClick={props.switch}><Icon.X/></button></li>
            {menuitems.map(item=><li key={'menuitem'+item.id} className="nav-item">
                <a className="nav-link" href={item.link}>{item.name}</a>
            </li>)}
        </ul>
    </nav>
}


export function fetching(url,fn,state){
    let urlval =state.props.global.domainApi+url+'/'+state.props.global.apicode
    fetch(urlval).then((response)=>{
        return response.text();
    }).then((json)=>{
        json=JSON.parse(json)
        fn(json)
    })
}

export function fetchingd(url,fn,global){
    let urlval =global.domainApi+url+'/'+global.apicode
    fetch(urlval).then((response)=>{
        return response.text();
    }).then((json)=>{
        json=JSON.parse(json)
        fn(json)
    })
}

export function fetchingWhole(url,fn,state){
    let urlval =state.props.global.domainApi+url+'/'+state.props.global.apicode
    fetch(urlval).then((response)=>{
        return response.text();
    }).then((json)=>{
        json=JSON.parse(json)
        fn(json)
    })
}

export const Loader = () => <div className={'d-flex align-items-center text-center'}>
            <div className="lds-ripple ">
                <div></div>
                <div></div>
            </div>
        </div>


export function Title(props) {
    return(
        <h3 className={props.class} dangerouslySetInnerHTML={{__html:props.value}}></h3>
    )
}

export function Titlesecond(props) {
    return(
        <h3 className={props.class} dangerouslySetInnerHTML={{__html:props.value}}></h3>
    )
}

export function Titlethird(props) {
    return(
        <h5 className={props.class+' mt-3 font-weight-bold mb-3'}>{props.value}</h5>
    )
}

export function Titlefourth(props) {
    return(
        <h5 className={props.class+' font-weight-regular'}>{props.value}</h5>
    )
}

export function Link(props) {
    if(props.link !== null){
        return(
            <Button className={props.class} href={props.link} target={props.target} variant={props.variant}>{props.value}</Button>
        )
    }else{
        return(
            <Button className={props.class} variant={props.variant}>{props.value}</Button>
        )
    }

}

export function Card(props) {
    return(
        <div className={'card card-body py-4 mt-4 bgblack text-white'} dangerouslySetInnerHTML={{__html:props.value}}>
        </div>
    )
}