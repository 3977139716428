import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import { Loader, fetchData} from "../General";
import {Helmet} from "react-helmet";

const Promo = (props) => {
    let { global }=props
    const [content, setContent] = useState(null)

    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/view.json?id=53`, setContent)
    },[])
    
    return <>
        {content !== null ?
        <div className={'bg-white border-bottom-yellow'}>
            <Helmet>
                <title>{`${content.content.title} - Vimenca Western Union`}</title>
                <meta name="description" content={content.content.metadescription} />
                <meta name="keywords" content={content.content.metakeywords} />
            </Helmet>
            {/* <div className={'mainimg border-bottom'}>
                <img className={'w-100'} alt={content.content.title} src={global.domain+'/'+content.content.panoimg} />
            </div> */}
            <div className={'container p-5'}>
                <Row>
                    <Col className="text-center">
                        <img 
                            src="/files/banner-mx-promo-index-content-2.jpg"
                            alt="Promo Banner"
                            className="img-fluid mx-auto d-block"
                            style={{ maxWidth: '680px', width: '100%', height: 'auto' }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
        : <Loader />
        }
        </>

}

export default Promo;
