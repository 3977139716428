import React, {useEffect, useState} from 'react'
import Icon from 'feather-icons-react';
import {Carousel,Container,Row,Col} from 'react-bootstrap'
import {Title,Link,Titlesecond,Subtitle,Titlethird,Loader,Itemnew,ServiciosTransferencia, fetchData} from './../General'
import {Helmet} from "react-helmet";

function Offices(props){
    let data=props.data;
    return(
        <div className={'p-5'} style={{backgroundImage:'url('+props.domain+'/img/mpa.jpg)', backgroundSize: 'cover'}}>
            <Container className={'text-center py-5'}>
                <Subtitle value={data.subtitle} bg={'bg-black'} fontcolor={'text-white'}/>
                <Titlesecond class={'text-white mt-5'} value={data.title} />
                <div className={'mb-3 text-white text-center'}><p>Encuentra la oficina más cerca de ti.  Ponemos a tu disposición nuestra red de más de 300 oficinas a nivel nacional.</p></div>
                <Link class={'mt-5'} link={'/oficinas'} variant={'primary'} value={'Encuentra la más cercana'}/>
            </Container>
        </div>
    )
}

function Banners(props){
    let data=props.data
    let setting=props.setting
    return(
        <div id={'home'} className={'home-banner bg-white text-center pt-0'}>
            <Carousel controls={setting.showarrows} fade={true}>
                {
                    data.map(function (item){
                        return(
                            <Carousel.Item key={'itembanner'+item.id} interval={3000}>
                                <a target={item.blank_page ? '_blank' : null} href={item.link}>
                                    <div className={'bannercontainer'}>
                                        <img alt={item.name} className={'bannerimg'}  src={props.domain+item.img}/>
                                    </div>
                                </a>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

function About(props) {
    return(
        <div className={'whitecontent py-5'}>
            <Container className={'p-5'}>
                <Row>
                    <Col>
                        <Title class={'mt-0 mb-4'} value={props.data.title} />
                        <div dangerouslySetInnerHTML={{__html:props.data.content}}></div>
                        <Link link={'/'+props.data.alias+'/'+props.data.id} class={'mt-4'} variant={'secondary'} value={'Conocer más'}/>
                    </Col>
                    {props.data.img !== null ? <Col xs={12} md={4}>
                        <img alt={props.data.title} src={props.domain+props.data.img}/>
                    </Col> : null}
                </Row>
            </Container>
        </div>
    )
}

function Services(props) {
    let services=props.data.items
    let countservices=services.length
    let itemperpage=3
    let pages=Math.ceil(countservices/itemperpage)
    let result = []
    let countall=0
    let counteritem=0
    for(let a = 1 ; a <= pages; a++){
        let itemar=[]
        for(let serv=1; serv <= itemperpage; serv++) {
            countall++
            if(countall <= countservices){
                itemar.push(<Col key={'services' + services[counteritem].id} className={'text-center mb-2'} xs={12} sd={6} md={4}>
                    <div className={'itemservicecontent'}><span className={'text-black'}><Icon icon={services[counteritem].icon} size={65}/></span>
                    <Titlethird value={services[counteritem].title}/>
                    <p>{services[counteritem].short}</p>
                    </div>
                    <Link class={'mt-3'} target={'_self'}
                          link={'/servicio/' + services[counteritem].alias + '/' + services[counteritem].id} variant={'secondary'}
                          value={'Conocer más'}/></Col>)
            }
            counteritem++
        }
        result.push(<Carousel.Item key={'itemservice'+a}><Row className={'mt-5 justify-content-center'}>{itemar}</Row></Carousel.Item>)
    }
    return(
        <div className={'services bgyellow py-5'}>
            <Container className={'p-5'}>
                <Row>
                    <Col className={'text-center'} xs={12}>
                        <Subtitle bg={'bg-black'} fontcolor={'text-white'} value={'Servicios'}/>
                        <Titlesecond class={'mt-3'} value={props.data.content.title} />
                        <div dangerouslySetInnerHTML={{__html:props.data.content.content}}></div>
                    </Col>
                </Row>
                <Carousel>
                    {result}
                </Carousel>
            </Container>
        </div>
    )
}

function Noticias(props) {
    let noticias=props.data.items
    let banners=props.data.banners
    let setting=props.data.bannerssetting
    let domain=props.domain
    return(
        <div className={'bg-white py-5'}>
            <Container className={'p-5'}>
                <Row>
                    <Col xs={12} md={8}>
                        <Subtitle bg={'bgyellow'} value={'Últimas Noticias'}/>
                        <Row className={'mt-3'}>
                            {noticias.map(function(item){
                                return(
                                    <Itemnew md={6} domain={domain} key={'news'+item.id} item={item}/>
                                )
                            })}
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Subtitle bg={'bg-white'} value={'Buscamos talentos'}/>
                        <Carousel controls={setting.showarrows}  className={'mt-3'}>
                            {
                                banners.map(function (item){
                                    return(
                                        <Carousel.Item key={'itembannersuc'+item.id} style={{height:'auto'}}>
                                                <a href={item.link}><img alt={item.name} className={'bannerimg h-100'}  src={props.domain+'/'+item.img}/></a>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

const Home2 = (props) => {
    let {global, main}=props
    let [loadedContent, setIsLoadedContent] = useState(null)

    useEffect( () => {
        fetchData(`${global.domain}api-portal/content/home.json`, setIsLoadedContent)
    },[])

    loadedContent = loadedContent?.get
    main = main?.get
    console.log(main.main.name)
    return loadedContent ?
        <div className="">
            <Helmet>
                <title>{`${main.main.name}`}</title>
                <meta name="description" content={main.main.metadescription} />
                <meta name="keywords" content={main.main.metakeywords} />
            </Helmet>
            <Banners domain={global.domain} data={loadedContent.banners} setting={loadedContent.bannerssetting}/>
            <About domain={global.domain} data={loadedContent.content} />
            <Services domain={global.domain} data={loadedContent.services} />
            <Noticias domain={global.domain} data={loadedContent.news} />
            <Offices domain={global.domain} data={loadedContent.offices} />
            <ServiciosTransferencia className={'border-bottom bg-white'} data={main.bannericonos} domain={global.domain}/>
        </div>
        :
        <Container className={'min-vh-100 h-100'} style={{height:'100%'}}>
            <Helmet>
                <title>{main.name}</title>
                <meta name="description" content={main.metadescription} />
                <meta name="keywords" content={main.metakeywords} />
            </Helmet>
            <Loader/>
        </Container>
}

export default Home2;
